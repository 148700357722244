const swrParallelSuspenseMiddlware = (useSWRNext) => (key, fetcher, config) => {
  if (!config.suspense) {
    return useSWRNext(key, fetcher, config);
  }

  let promise;
  try {
    return useSWRNext(key, fetcher, config);
  } catch (err) {
    if (err instanceof Promise) {
      promise = err;
    } else {
      throw err;
    }
  }

  return {
    data: new Proxy({}, {
      get() {
        throw promise;
      },
    }),
  };
};

export default swrParallelSuspenseMiddlware;
