import { IconButton, InputAdornment } from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import React, { useState } from 'react';

const VisibilityToggle = ({ children, ...props }) => {
  const [isVisible, setIsVisible] = useState(false);

  const endAdornment = (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle visibility"
        onClick={() => setIsVisible(!isVisible)}
        edge="end"
      >
        {isVisible ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );
  return children({ isVisible, endAdornment, ...props });
};

export default VisibilityToggle;
