import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Error from '@mui/icons-material/Error';

const getValidity = (isValid, maskInvalid) => {
  if (isValid) {
    return {
      validity: 'valid',
      Icon: CheckCircle,
      color: 'success.main',
    };
  }

  if (maskInvalid) {
    return {
      validity: 'neutral',
      Icon: CheckCircleOutlined,
      color: 'info.main',
    };
  }

  return {
    validity: 'invalid',
    Icon: Error,
    color: 'error.main',
  };
};

const NewPasswordHint = ({
  rule, maskInvalid, isValid, children,
}) => {
  const { Icon, color, validity } = getValidity(isValid, maskInvalid);
  const testId = `${rule}-${validity}`;
  return (
    <ListItem data-testid={testId} sx={{ color }} disablePadding>
      <ListItemIcon sx={{ minWidth: 0 }}>
        <Icon fontSize="small" sx={{ color }} />
      </ListItemIcon>
      <ListItemText primaryTypographyProps={{ fontSize: '0.75rem' }}>
        {children}
      </ListItemText>
    </ListItem>
  );
};

export default NewPasswordHint;
