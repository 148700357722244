import React from 'react';
import { Navigate } from 'react-router-dom';
import HardNavigate from '../HardNavigate';
import index from '../../hooks/useRedirectUri';

const RedirectUnauthenticated = () => {
  const redirectUri = index();
  if (redirectUri) {
    return <HardNavigate to={redirectUri} />;
  }

  return <Navigate to="/" replace />;
};

export default RedirectUnauthenticated;
