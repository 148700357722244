import React from 'react';
import useLogout from '../../resources/session/logout';
import useTitle from '../../utils/useTitle';
import Loading from '../Loading';

const Logout = () => {
  useTitle('Logging out...');
  useLogout();
  return <Loading />;
};

export default Logout;
