import { useSearchParams } from 'react-router-dom';

/**
 * Parses the URL path and restricts it to the current origin.
 * Returns a sanitized path.
 * Returns null if no path is provided or if parsing fails.
 * @param {string} path
 * @return {string|null}
 */
const sanitizeRedirectPath = (path) => {
  if (!path) {
    return null;
  }

  try {
    return new URL(path, origin).pathname;
  } catch {
    return null;
  }
};

const useRedirectUri = () => {
  const [searchParams] = useSearchParams();
  const redirectUri = searchParams.get('redirect_uri');
  return sanitizeRedirectPath(redirectUri);
};

export default useRedirectUri;
