import React from 'react';
import { Button } from '@mui/material';

import styles from './styles.css';

const Message = ({
  title,
  text,
  buttonText = 'Return to Sign In',
  onButtonClick,
}) => (
  <>
    <h2>
      {title}
    </h2>
    <p className={styles.text}>
      {text}
    </p>
    <Button
      fullWidth
      onClick={onButtonClick}
    >
      {buttonText}
    </Button>
  </>
);

export default Message;
