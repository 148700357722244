const errorInterceptor = (error) => {
  if (error?.response?.status === 401) {
    // unauthorized requests are routed to auth
    window.location.replace(`/${process.env.PREFIX}/auth?redirect_uri=${window.location.pathname}`);
    // keep the underlying request pending
    // so any loading states will remain active
    // during the hard navigation
    return new Promise(() => {});
  }

  if (error?.code === 'ECONNABORTED') {
    // aborted requests are the result of cancellation middleware
    // at the cache layer. no need to let these hit the view layer

    // keep the underlying request pending
    // so any loading states will remain active
    // during the hard navigation
    return new Promise(() => {});
  }

  // 401 errors result in a hard navigation to auth
  // non-401 errors pass through unmodified
  return Promise.reject(error);
};

export default errorInterceptor;
