import { Outlet } from 'react-router-dom';
import React from 'react';
import Checkpoint from '../Checkpoint';
import RedirectAuthenticated from '../RedirectAuthenticated';

const CheckpointUnauthenticated = () => (
  <Checkpoint
    authenticated={false}
    Mismatch={RedirectAuthenticated}
  >
    <Outlet />
  </Checkpoint>
);

export default CheckpointUnauthenticated;
