import React from 'react';

import Message from '../Message';
import BareLayout from '../BareLayout';

const FatalError = ({ resetErrorBoundary }) => (
  <BareLayout>
    <Message
      title="Oops! Something went wrong."
      text="We made a note of it. Please try again."
      buttonText="Retry"
      onButtonClick={resetErrorBoundary}
    />
  </BareLayout>
);

export default FatalError;
