import baseFetcher from './fetcher';

const noErrorFetcher = async (...args) => {
  try {
    return await baseFetcher(...args);
  } catch {
    return null;
  }
};

export default noErrorFetcher;
