import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, CircularProgress, TextField } from '@mui/material';
import ValidatedTextField from 'common-mui/components/ValidatedTextField';
import { EMAIL_REGEX } from 'common-constants/validation';
import useSWRMutationForm from 'common-form/useSWRMutationForm';
import Form from '../Form';
import Message from '../Message';
import useTitle from '../../utils/useTitle';
import useForgotPassword from '../../resources/session/forgotPassword';

const Forgot = () => {
  useTitle('Password Reset');
  const [wasSuccessful, setWasSuccessful] = useState(undefined);
  const navigate = useNavigate();

  const {
    isSubmitting,
    handleSubmit,
    control,
    getValues,
  } = useSWRMutationForm({
    useMutation: () => useForgotPassword({
      onSuccess: () => {
        setWasSuccessful(true);
      },
    }),
  });

  if (wasSuccessful) {
    return (
      <Message
        title="Check your inbox"
        text={(
          <span>
            We just emailed
            {' '}
            <strong>
              {getValues().email}
            </strong>
            {' '}
            with instructions on how to reset your password.
          </span>
        )}
        onButtonClick={() => navigate('/', { replace: true })}
      />
    );
  }

  return (
    <Form
      title="Password reset"
      subtitle="If you have an active DigniFi account, we'll send you an email with instructions on how to reset your password."
      onSubmit={handleSubmit}
    >
      <ValidatedTextField
        name="email"
        control={control}
        rules={{
          required: 'Email is required.',
          pattern: {
            value: EMAIL_REGEX,
            message: 'Please check that your email is in the format: name@domain.com.',
          },
        }}
      >
        <TextField
          fullWidth
          label="Email"
        />
      </ValidatedTextField>
      <Button
        fullWidth
        type="submit"
        disabled={isSubmitting}
        startIcon={isSubmitting && <CircularProgress size={18} sx={{ color: '#FFFFFF' }} />}
      >
        Send reset instructions
      </Button>
      <Button
        fullWidth
        component={Link}
        to="/"
        variant="text"
        size="small"
      >
        Return to Sign In
      </Button>
    </Form>
  );
};

export default Forgot;
