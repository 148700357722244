import React, { useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { Button, CircularProgress, TextField } from '@mui/material';
import NewPassword from 'common-mui/components/NewPassword';
import useSWRMutationForm from 'common-form/useSWRMutationForm';
import Form from '../Form';
import Message from '../Message';
import useTitle from '../../utils/useTitle';
import useResetPassword from '../../resources/session/resetPassword';
import useSession from '../../resources/session/read';

const INVALID_RESET_TOKEN = 'INVALID_RESET_TOKEN';

const SetPassword = ({
  documentTitle,
  title,
  subtitle,
  successText,
  legalText,
  submitText,
  signInText,
  invalidTokenMessage,
}) => {
  useTitle(documentTitle);
  const { mutate } = useSession();
  const [searchParams] = useSearchParams();
  const [tempSession, setTempSession] = useState(null);

  const email = searchParams.get('email');
  const confirmationCode = searchParams.get('token');

  const {
    handleSubmit,
    isSubmitting,
    error,
    ...form
  } = useSWRMutationForm({
    onSubmit: (data) => ({
      password: data.password,
      email,
      confirmationCode,
    }),
    useMutation: () => useResetPassword({
      // Store the session payload in a temp variable,
      // so we can flash a success message before updating
      // the local session
      onSuccess: setTempSession,
    }),
    errorSchema: { [INVALID_RESET_TOKEN]: {} },
  });

  if (!confirmationCode || !email) {
    return <Navigate to="/" replace />;
  }

  if (tempSession) {
    return (
      <Message
        title="Success!"
        text={successText}
        buttonText="Continue"
        // Update the local session with the result of the mutation
        // so the Checkpoint can initiate the redirect
        onButtonClick={() => mutate(tempSession, { revalidate: false })}
      />
    );
  }

  return (
    <Form
      title={title}
      subtitle={subtitle}
      error={error === INVALID_RESET_TOKEN && invalidTokenMessage}
      onSubmit={handleSubmit}
    >
      <TextField
        disabled
        fullWidth
        value={email}
        label="Email"
      />
      <NewPassword form={form} />
      {legalText}
      <Button
        type="submit"
        startIcon={isSubmitting && <CircularProgress size={18} sx={{ color: '#FFFFFF' }} />}
        disabled={isSubmitting}
        fullWidth
      >
        {submitText}
      </Button>
      {signInText}
    </Form>
  );
};

export default SetPassword;
