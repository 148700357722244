import React from 'react';
import { CircularProgress } from '@mui/material';

import styles from './styles.css';

const Loading = () => (
  <div className={styles.container}>
    <CircularProgress size={80} sx={{ color: '#AB2328' }} />
  </div>
);

export default Loading;
