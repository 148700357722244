import React from 'react';
import { Controller } from 'react-hook-form';

const ValidatedTextField = ({
  name,
  children,
  getError = (x) => x,
  getHelperText = (x) => x,
  defaultValue = '',
  ...controllerProps
}) => (
  <Controller
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...controllerProps}
    name={name}
    defaultValue={defaultValue}
    render={({
      field,
      fieldState,
      formState,
    }) => {
      const TextFieldComponent = React.Children.only(children);

      const {
        onChange = () => {},
        onBlur = () => {},
        ...restTextFieldProps
      } = TextFieldComponent.props;

      const handleChange = (e) => {
        field.onChange(e);
        onChange(e);
      };

      const handleBlur = (e) => {
        field.onBlur();
        onBlur(e);
      };

      const serverError = formState?.errors?.root?.[name];
      return (
        // eslint-disable-next-line react/jsx-pascal-case
        <TextFieldComponent.type
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...restTextFieldProps}
          inputRef={field.ref}
          value={field.value}
          name={field.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={getError(fieldState.invalid || !!serverError)}
          helperText={getHelperText(fieldState.error?.message || serverError?.message)}
        />
      );
    }}
  />
);

export default ValidatedTextField;
