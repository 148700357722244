import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';

import styles from './styles.css';

const Layout = () => (
  <>
    <Header />
    <div className={styles.container}>
      <div className={styles.backdropTop} />
      <div className={styles.backdropBottom} />
      <div className={styles.tile}>
        <Outlet />
      </div>
    </div>
    <Footer />
  </>
);

export default Layout;
