import React from 'react';
import BaseConfig from 'common-swr/components/SWRConfig';
import fetcher from '../../resources/shared/fetcher';

const SWRConfig = ({ children }) => (
  <BaseConfig
    fetcher={fetcher}
    use={[]}
  >
    {children}
  </BaseConfig>
);

export default SWRConfig;
