import React from 'react';
import cx from 'classnames';

import logo from '../../images/bbb-rating-graphic.png';
import styles from './styles.css';

const Footer = () => (
  <div className={styles.container}>
    <div className={cx(styles.subContainerCol, styles.mx)}>
      <div className={cx(styles.subContainerFlexible, styles.mt)}>
        <div className={styles.subContainerCol}>
          <p className={styles.boldLabel}>Why DigniFi?</p>
          <p className={styles.labelText}>
            Check out our
            {' '}
            <a className={styles.linkText} href="https://www.dignifi.com/consumer-faq/">FAQ</a>
          </p>
        </div>
        <img
          src={logo}
          alt="Better Business Bureau Logo"
          className={styles.bbbLogo}
        />
      </div>
      <div className={styles.subContainerCol}>
        <p className={cx(styles.explanatoryText, styles.mt)}>
          Credit products are owned & originated by WebBank. Pre-qualification of your
          creditworthiness does not guarantee approval of any financing. Loan application may
          impact your credit score.
        </p>
        <p className={cx(styles.brandText, styles.mb)}>
          © DigniFi
          {' '}
          {new Date().getFullYear()}
        </p>
      </div>
    </div>
  </div>
);

export default Footer;
