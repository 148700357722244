import React from 'react';
import useSession from '../../resources/session/read';

const Checkpoint = ({
  authenticated = true,
  Mismatch,
  children,
}) => {
  const { data: session } = useSession();
  if (Boolean(session) === !authenticated) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Mismatch />;
  }
  return children;
};

export default Checkpoint;
