/* istanbul ignore file */
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

export { datadogLogs } from '@datadog/browser-logs';
export { datadogRum } from '@datadog/browser-rum';

const clientToken = 'puba85d29bb928cb939fb52969def6d9941';

const init = (app) => {
  if (process.env.STAGE === 'local' || process.env.STAGE === 'dev') {
    return;
  }

  const env = process.env.STAGE;
  const version = process.env.VERSION;
  const service = `ui/${app}`;

  datadogLogs.init({
    clientToken,
    service,
    env,
    version,
  });

  datadogRum.init({
    applicationId: 'cdb495c5-48dc-40c4-8fd2-1f2b6315b356',
    clientToken,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    proxy: ({ path, parameters }) => `/rum-intake${path}?${parameters}`,
    allowedTracingUrls: [window.location.origin],
    service,
    env,
    version,
  });
};

export default init;
