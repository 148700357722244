import React from 'react';
import { SWRConfig as DefaultConfig } from 'swr';
import getFetcher from '../../utils/fetcher';
import cancellation from '../../utils/cancellation';
import parallelism from '../../utils/parallelism';

const fetcher = getFetcher();

const SWRConfig = ({ children, ...rest }) => (
  <DefaultConfig value={{
    provider: () => new Map(),
    fetcher,
    use: [cancellation, parallelism],
    ...rest,
  }}
  >
    {children}
  </DefaultConfig>
);

export default SWRConfig;
