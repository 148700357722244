import axios from 'axios';
import errorInterceptor from './errorInterceptor';

const client = axios.create({
  baseURL: '/platform',
});

client.interceptors.response.use(
  // successful request interceptor: pass through unmodified
  (response) => response,
  // error request interceptor: special handling for 401s
  errorInterceptor,
);
export default client;
