import { useEffect, useMemo } from 'react';
// eslint-disable-next-line camelcase
import { unstable_serialize } from 'swr';

const cancellationMiddleware = (useSWRNext) => (key, fetcher, config) => {
  const serializedKey = unstable_serialize(key);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const controller = useMemo(() => new AbortController(), [serializedKey]);
  const wrappedFetcher = (req, ...args) => fetcher({ ...req, signal: controller.signal }, ...args);
  const swr = useSWRNext(key, wrappedFetcher, config);
  useEffect(() => () => controller.abort(), [controller, serializedKey]);
  return swr;
};

export default cancellationMiddleware;
