/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { TextField } from '@mui/material';
import VisibilityToggle from '../VisibilityToggle';

const Password = (passwordProps) => (
  <VisibilityToggle>
    {({ isVisible, endAdornment, ...visibilityToggleProps }) => (
      <TextField
        {...passwordProps}
        {...visibilityToggleProps}
        fullWidth
        label="Password"
        type={isVisible ? 'text' : 'password'}
        InputProps={{ endAdornment }}
      />
    )}
  </VisibilityToggle>
);

export default Password;
