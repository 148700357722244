import useSWRMutation from 'swr/mutation';
import { useEffect } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { KEY } from '../constants';
import baseFetcher from '../../shared/fetcher';

const logoutFetcher = () => baseFetcher({ method: 'POST', url: '/auth/authentication/logout' });

const useLogout = () => {
  const { showBoundary } = useErrorBoundary();
  const { trigger } = useSWRMutation(KEY, logoutFetcher, {
    revalidate: false,
    populateCache: true,
  });
  useEffect(() => {
    (async () => {
      try {
        await trigger();
      } catch (err) {
        showBoundary(err);
      }
    })();
  }, [showBoundary, trigger]);
};

export default useLogout;
