import React from 'react';
import cx from 'classnames';
import logo from 'common-img/dignifi-logo-color.svg';
import styles from './styles.css';

const Header = () => (
  <div className={cx(styles.container, styles.mx, styles.my)}>
    <img
      src={logo}
      alt="DigniFi"
      className={styles.digniFiLogo}
    />
    <div className={styles.subContainer}>
      <p className={cx(styles.labelText, styles.textMargin, styles.mr)}>Want financing?</p>
      <a href={`/${process.env.PREFIX}/apply`}>Get Prequalified</a>
    </div>
  </div>
);

export default Header;
