/* istanbul ignore file */
import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import initDataDog from 'common-datadog';

import './styles/styles.css';
import { ThemeProvider } from '@mui/material/styles';
import muiTheme from 'common-mui/theme';
import { Backdrop, CssBaseline } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import FatalError from './components/FatalError';
import SWRConfig from './components/SWRConfig';
import Loading from './components/Loading';
import Router from './components/Router';

initDataDog('auth');

const container = document.getElementById('dignifi-ui');
const root = createRoot(container);

root.render(
  <BrowserRouter basename={`/${process.env.PREFIX}/auth`}>
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      <ErrorBoundary fallbackRender={FatalError}>
        <SWRConfig>
          <Suspense
            fallback={(
              <Backdrop sx={{ backgroundColor: '#F7F9FB' }} open>
                <Loading />
              </Backdrop>
            )}
          >
            <Router />
          </Suspense>
        </SWRConfig>
      </ErrorBoundary>
    </ThemeProvider>
  </BrowserRouter>,
);
