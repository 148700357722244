import { preload } from 'swr';
import useSWRImmutable from 'swr/immutable';
import { KEY } from '../constants';
import noErrorFetcher from '../../shared/noErrorFetcher';

const fetcher = () => noErrorFetcher({ url: '/auth/users/me' });

const useSession = () => useSWRImmutable(KEY, fetcher, { suspense: true });

preload(KEY, fetcher);

export default useSession;
