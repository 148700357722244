import React from 'react';
import { EXTERNAL_PARTNER, PEACH } from './constants';
import HardNavigate from '../HardNavigate';
import useSession from '../../resources/session/read';
import usePrequal from '../../resources/prequal/read';
import useRedirectUri from '../../hooks/useRedirectUri';

const AuthenticatedRedirects = () => {
  const { data: session } = useSession();
  const { data: prequal } = usePrequal();
  const redirectUri = useRedirectUri();

  if (session.role === EXTERNAL_PARTNER) {
    return <HardNavigate to={`/${process.env.PREFIX}/partners`} />;
  }

  if (session.servicingPlatform === PEACH && session.contracted) {
    return <HardNavigate to={process.env.PEACH_AUTH_URL} />;
  }

  if (prequal) {
    return <HardNavigate to={`/${process.env.PREFIX}/apply`} />;
  }

  if (redirectUri) {
    return <HardNavigate to={redirectUri} />;
  }

  return <HardNavigate to="/home" />;
};

export default AuthenticatedRedirects;
