import Header from 'common-mui/components/Header';
import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

const container = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  flex: 1,
  margin: '20px',
};

const formContainer = {
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '500px',
  width: '100%',
  flex: 1,
  marginTop: '20px',
};

const BareLayout = ({ children }) => (
  <Box sx={container}>
    <Header />
    <Box sx={formContainer}>
      { children || <Outlet />}
    </Box>
  </Box>
);

export default BareLayout;
