import React from 'react';
import Alert from '@mui/material/Alert';
import styles from './styles.css';

const Form = ({
  title,
  subtitle,
  error,
  onSubmit,
  children,
}) => (
  <>
    <h2>
      {title}
    </h2>
    { subtitle && (
    <p className="centered">
      {subtitle}
    </p>
    )}
    { error && (
    <Alert className={styles.error} severity="error">
      {error}
    </Alert>
    )}
    <form
      className={styles.form}
      onSubmit={onSubmit}
    >
      {children}
    </form>
  </>
);

export default Form;
