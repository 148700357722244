import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import NewPasswordHint from '../NewPasswordHint';
import ValidatedTextField from '../ValidatedTextField';
import Password from '../Password';

const RULES = [
  {
    name: 'length',
    description: 'Between 8-100 characters',
    validate: ({ length }) => length >= 8 && length <= 100,
  }, {
    name: 'uppercase',
    description: 'At least one uppercase letter (A-Z)',
    validate: (v) => /[A-Z]/.test(v),
  }, {
    name: 'lowercase',
    description: 'At least one lowercase letter (a-z)',
    validate: (v) => /[a-z]/.test(v),
  }, {
    name: 'number',
    description: 'At least one number (0-9)',
    validate: (v) => /[0-9]/.test(v),
  }, {
    name: 'special',
    description: 'At least one special character (!@][.{}()#$%^&+=~`:,;-<>_?*)',
    validate: (v) => /[!@\][.{}()#$%^&+=~`:,;\-<>_?*]/.test(v),
  },
];

const validationRules = {
  validate: RULES.reduce((acc, { name, validate }) => ({ ...acc, [name]: validate }), {}),
};

const NewPassword = ({
  form: {
    formState,
    control,
    trigger,
    getFieldState,
  },
}) => {
  const [hasFocused, setHasFocused] = useState(false);
  const { isSubmitted, errors: { password: errors } } = formState;
  const { isTouched } = getFieldState('password', formState);

  useEffect(() => {
    trigger('password');
  }, [trigger]);

  const maskInvalid = !isTouched && !isSubmitted;
  return (
    <div>
      <ValidatedTextField
        name="password"
        control={control}
        getError={(error) => !maskInvalid && error}
        getHelperText={(text) => text || (hasFocused && 'Your password must contain the following:')}
        rules={validationRules}
      >
        <Password
          autoComplete="new-password"
          onChange={() => trigger('password')}
          onFocus={() => setHasFocused(true)}
        />
      </ValidatedTextField>
      { (hasFocused || isSubmitted) && (
        <List data-testid="password-hints" dense>
          {RULES.map(({ name, description }) => ((
            <NewPasswordHint
              key={name}
              rule={name}
              maskInvalid={maskInvalid}
              isValid={!errors?.types?.[name]}
            >
              { description }
            </NewPasswordHint>
          )))}
        </List>
      )}
    </div>
  );
};

export default NewPassword;
