import useSWRMutation from 'swr/mutation';
import baseFetcher from '../../shared/fetcher';
import { KEY } from '../constants';

const fetcher = (_, { arg: data }) => baseFetcher({
  method: 'POST',
  url: '/auth/authentication/login',
  data,
});

const useCreateSession = () => (
  useSWRMutation(KEY, fetcher, { revalidate: false, populateCache: true })
);

export default useCreateSession;
