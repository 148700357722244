import React from 'react';
import DignifiLogo from 'common-img/dignifi-logo-color.svg';
import styles from './header.module.css';

const Header = () => (
  <div className={styles.container}>
    <img
      src={DignifiLogo}
      alt="Dignifi"
      className={styles.logo}
    />
  </div>
);

export default Header;
