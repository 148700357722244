import { Outlet } from 'react-router-dom';
import React from 'react';
import Checkpoint from '../Checkpoint';
import RedirectUnauthenticated from '../RedirectUnauthenticated';

const CheckpointAuthenticated = () => (
  <Checkpoint
    authenticated
    Mismatch={RedirectUnauthenticated}
  >
    <Outlet />
  </Checkpoint>
);

export default CheckpointAuthenticated;
