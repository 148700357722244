import React from 'react';
import { Typography } from '@mui/material';
import MutatePassword from '../SetPassword';

const CreatePasswordExternal = () => (
  <MutatePassword
    documentTitle="Create Account"
    title="Create an account"
    subtitle="Welcome to DigniFi! Set your password to view your offer."
    successText="Your account has been created."
    invalidTokenMessage="The account creation email we sent has expired. Please contact support."
    submitText="Create account"
    legalText={(
      <Typography variant="body2" textAlign="center">
        By clicking “Create Account” you agree to DigniFi&apos;s
        {' '}
        <a
          href="https://app.dignifi.com/privacy"
          target="_blank"
          rel="noreferrer"
          style={{ fontSize: 'inherit' }}
        >
          Privacy Policy
        </a>
        {' '}
        and
        {' '}
        <a
          href="https://www.dignifi.com/terms-of-use/"
          target="_blank"
          rel="noreferrer"
          style={{ fontSize: 'inherit' }}
        >
          Terms of Use
        </a>
      </Typography>
    )}
  />
);

export default CreatePasswordExternal;
